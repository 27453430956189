var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_vm._t("navbar", [_c('profile-header')])], 2), _c('div', {
    staticClass: "mt-2 mb-4"
  }, [_c('center', [_c('span', {
    staticClass: "text-center",
    staticStyle: {
      "font-size": "3.5rem",
      "color": "black"
    }
  }, [_c('b', [_c('span', {
    staticClass: "text-primary"
  }, [_vm._v("Paket Tryout")])])]), _c('br'), _c('h4', {
    staticClass: "col-sm-11 col-md-9 mt-2"
  }, [_vm._v(" Hallo Pejuang PTN… Ukur, Asah Kemampuanmu Mengerjakan Soal Bersama Einstein Learning Plus dengan Ribuan Soal Ter Update Yang Sudah TERBUKTI MELOLOSKAN Ribuan Siswa Setiap Tahunnya. Kejar Persiapan SNBP dan SNBT dari sekarang !!! saatnya menguji sudah berapa jauh progres belajarmu dengan TOBK (Tryout Berbasis Komputer) Einstein Learning Plus. ")]), _c('br'), _c('br')]), _c('div', {
    staticClass: "container-fluid",
    staticStyle: {
      "width": "90%"
    }
  }, [_c('b-breadcrumb', {
    staticClass: "breadcrumb-chevron"
  }, [_c('b-breadcrumb-item', {
    attrs: {
      "to": {
        name: 'home'
      }
    }
  }, [_vm._v(" Beranda ")]), _c('b-breadcrumb-item', {
    attrs: {
      "active": ""
    }
  }, [_vm._v(" Paket Try Out Selengkapnya ")])], 1)], 1)], 1), _c('div', {
    staticClass: "container-fluid",
    staticStyle: {
      "width": "90%"
    }
  }, [_c('b-row', _vm._l(_vm.data, function (item, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "md": "6",
        "xl": "3"
      }
    }, [_c('b-card', {
      staticClass: "mb-2",
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "img-src": item.thumbnail != null ? _vm.apiFile + item.thumbnail : require('@/assets/images/elp/no-image.jpg'),
        "img-top": "",
        "img-alt": "card img",
        "sub-title": item.sum_subtest_content + " subtes"
      },
      on: {
        "click": function click($event) {
          return _vm.detail(item);
        }
      }
    }, [_c('b-card-text', [_c('b', [_vm._v(_vm._s(item.name))])]), _c('div', {
      staticClass: "card__arrow",
      style: 'background:' + _vm.getColor(item.category.color)
    })], 1)], 1);
  }), 1)], 1), _c('footer-home')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }